.row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  margin: 10px;
}

/* @media (min-width: 768px) {
    .col-md-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
} */

.col-md-4 {
  display: flex;
  width: 200px;
  @media (min-width: 1024px) {
    min-width: 30%;
  }

  gap: 15px;
  flex-direction: column;
  margin: 10px;
  color: #8d8e90;
}

.nm20 {
  margin-left: -20%;
}
